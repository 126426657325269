import { Model } from "@nozbe/watermelondb";
import { field, text } from "@nozbe/watermelondb/decorators";

export default class BackupPage extends Model {
  static table = "backup_page";
  // @ts-ignore
  @text("title") title: string;
  // @ts-ignore
  @text("body") body: string;
  // @ts-ignore
  @field("page_id") page_id: number;
}
