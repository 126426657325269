"user client";
import { userType } from "@/types";

function getCookie(name: string): string | undefined {
  let cookie: { [key: string]: string } = {};
  document.cookie.split(";").forEach(function (el) {
    let [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function getUserType(): userType {
  if (typeof window === "undefined") return "named";

  const cookieType = getCookie("userType");
  if (cookieType) {
    setUserType(cookieType as userType); // 쿠키가 있으면 만료 날짜 갱신
    return cookieType as userType;
  } else {
    // 쿠키가 없을 때 localStorage에서 값을 가져옴
    const localStorageType = localStorage.getItem("userType");
    if (localStorageType) {
      // localStorage 값으로 쿠키 재설정
      setUserType(localStorageType as userType);
      return localStorageType as userType;
    } else {
      return "named"; // 쿠키와 localStorage 모두 값이 없는 경우
    }
  }
}

export function setUserType(type: userType) {
  const expires = new Date("9999-01-01T00:00:00Z");
  document.cookie = `userType=${type}; expires=${expires.toUTCString()}; path=/`;
  localStorage.setItem("userType", type); // localStorage에도 백업
}
