// https://watermelondb.dev/docs/Advanced/Migrations
import {
  addColumns,
  createTable,
  schemaMigrations,
} from "@nozbe/watermelondb/Schema/migrations";
export default schemaMigrations({
  migrations: [
    {
      toVersion: 2,
      steps: [
        createTable({
          name: "page_offline",
          columns: [
            { name: "title", type: "string", isOptional: true },
            { name: "body", type: "string", isOptional: true },
            { name: "is_public", type: "boolean", isOptional: true },
            { name: "child_count", type: "number", isOptional: true },
            { name: "parent_count", type: "number", isOptional: true },
            { name: "last_viewed_at", type: "number", isOptional: true },
            { name: "img_url", type: "string", isOptional: true },
            { name: "length", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "page",
          columns: [
            { name: "title", type: "string", isOptional: true },
            { name: "body", type: "string", isOptional: true },
            { name: "is_public", type: "boolean", isOptional: true },
            { name: "child_count", type: "number", isOptional: true },
            { name: "parent_count", type: "number", isOptional: true },
            { name: "last_viewed_at", type: "number", isOptional: true },
            { name: "img_url", type: "string", isOptional: true },
            { name: "length", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
      ],
    },
  ],
});
