/*
1. migrations.ts에 마이그레이션을 정의한다. 
2. schema.ts에 스키마를 정의한다.
3. sync의 migrationsEnabledAtVersion을 1 증가 시킨다.
4. schmma.ts의 version을 1 증가 시킨다.
*/
import { appSchema, tableSchema } from "@nozbe/watermelondb";
export const schema = appSchema({
  version: 2,
  tables: [
    tableSchema({
      name: "backup_page",
      columns: [
        { name: "page_id", type: "string", isIndexed: true, isOptional: true },
        { name: "title", type: "string", isOptional: true },
        { name: "body", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "page",
      columns: [
        { name: "title", type: "string", isOptional: true },
        { name: "body", type: "string", isOptional: true },
        { name: "is_public", type: "boolean", isOptional: true },
        { name: "child_count", type: "number", isOptional: true },
        { name: "parent_count", type: "number", isOptional: true },
        { name: "last_viewed_at", type: "number", isOptional: true },
        { name: "img_url", type: "string", isOptional: true },
        { name: "length", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "page_offline",
      columns: [
        { name: "title", type: "string", isOptional: true },
        { name: "body", type: "string", isOptional: true },
        { name: "is_public", type: "boolean", isOptional: true },
        { name: "child_count", type: "number", isOptional: true },
        { name: "parent_count", type: "number", isOptional: true },
        { name: "last_viewed_at", type: "number", isOptional: true },
        { name: "img_url", type: "string", isOptional: true },
        { name: "length", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
  ],
});
