import { contentType } from "@/types";
import { atom } from "jotai";
import { atomWithImmer } from "jotai-immer";
import { atomWithStorage } from "jotai/utils";

export type finderAtomType = {
  open: boolean;
  mode: "search" | "ai" | null;
  inputMessage: string;
  ai: aiResponseType[];
  aiQueue: aiResponseQueueType;
};
export type aiResponseType = {
  id: number;
  referenceAll: aiReferenceType[];
  requestMessage: string;
  responseMessage: string;
  isModified?: boolean;
};
export type aiResponseQueueType = {
  status:
    | "-1_PENDING"
    | "0_BEFORE_SIMILARITY"
    | "1_BEFORE_LLM_ASK"
    | "2_COMPLETE";
};
export type aiReferenceType = {
  id: number;
  content: string;
  metadata: {
    id: number;
    type: "page" | "book" | "library";
    title: string;
  };
  similarity: number;
  selected: boolean;
};
export const aiAtom = atomWithImmer<finderAtomType>({
  open: false,
  mode: "ai",
  inputMessage: "",
  ai: [],
  aiQueue: { status: "-1_PENDING" },
});
// const [similarityResults] = atomsWithQuery((get) => ({
//   queryKey: ["similarity"],
//   queryFn: async ({ queryKey: [, id] }) => {
//     const res = await fetch(`https://jsonplaceholder.typicode.com/users/${id}`);
//     return res.json();
//   },
// }));

// 타입 정의 부분
export enum PlanItemType {
  PROMPT = "PROMPT",
  SIMILARITY = "SIMILARITY",
  LLM_ASK = "LLM_ASK",
}

export enum MessageType {
  Request = "request",
  SimilarityResponseStart = "similarity_response_start",
  SimilarityResponseEnd = "similarity_response_end",
  LLMResponse = "llm_request",
}

export type Prompt_data = {
  message: string;
};

export type Similarity_data = {
  range: "all" | "current";
  query?: string;
  id?: string;
} & (
  | { range: "all"; query: string; id?: never }
  | { range: "current"; query: string; id: string }
);

export type similarityResponse = {
  id: number;
  content: string;
  metadata: {
    type: string;
    title: string;
  };
  similarity: number;
  page_id: string;
};

export type LLM_ask_data = {
  llm_id: "openai" | "cohere";
  message: string;
  references: similarityResponse[];
  contextMessages: string[];
};

export type contextMessage = {
  role: "user" | "assistant";
  content: string;
};

export type askLLMContext = {
  message: string;
  references: similarityResponse[];
  history: contextMessage[];
  option: {
    ai: OptionItem | null;
    rag: OptionItem | null;
  };
};
export type OptionItem = {
  description: string;
  value: string;
  displayLabel: string;
  provider?: string;
};
export type PlanItem =
  | { type: PlanItemType.PROMPT; data: Prompt_data }
  | { type: PlanItemType.SIMILARITY; data: Similarity_data }
  | { type: PlanItemType.LLM_ASK; data: LLM_ask_data };

export type MessageItem = {
  id: string;
  type: MessageType;
  name: string;
  content: any;
};

// 양수 behavior: 'smooth', 음수 : none
export const chatScrollToBottomState = atom<number>(0);

// deprecated
export type chatSessionState = {
  inputMessage: string;
  messages: MessageItem[];
};

export const chatMessagesState = atomWithImmer<MessageItem[]>([]);

export const chatSessionInitial: chatSessionState = {
  inputMessage: "",
  messages: [],
};
// Jotai 상태 정의
export const chatSessionState =
  atomWithImmer<chatSessionState>(chatSessionInitial);

export const refreshSeedAfterContentUpdate = atom<number>(0);
export const typeSelectorState = atom({
  owner: "me",
  type: "page",
});
type snackbarStateType = {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error";
  autoHideDuration: number;
  horizontal: "left" | "center" | "right";
  vertical: "top" | "bottom";
};
const snackbarStateInit: snackbarStateType = {
  open: false,
  message: "",
  severity: "info",
  autoHideDuration: 3000,
  horizontal: "left",
  vertical: "bottom",
};
export const snackbarState = atom<snackbarStateType>(snackbarStateInit);

export const openSnackbarState = atom(null, (get, set, update) => {
  const data = get(snackbarState);
  // @ts-ignore
  set(snackbarState, { ...snackbarStateInit, ...update, open: true });
});

interface ConfirmStateType {
  open: boolean;
  message: string;
  onYes: (() => void) | false;
  onNo: (() => void) | null;
  yesLabel?: string;
  noLabel?: string;
}

export const confirmState = atom<ConfirmStateType>({
  open: false,
  message: "",
  onYes: false,
  onNo: null,
  yesLabel: "동의",
  noLabel: "취소",
});

export const openConfirmState = atom(
  null,
  (get, set, update: Partial<ConfirmStateType>) => {
    const current = get(confirmState);
    set(confirmState, { ...current, ...update, open: true });
  },
);

export const closeConfirmState = atom(null, (get, set) => {
  const data = get(confirmState);
  set(confirmState, { ...data, open: false });
});

export const darkModeState = atomWithStorage<boolean>("darkMode", true);

type initSearchMethodType = {
  keyword: string;
  start: string;
  end: string;
  sortingKey: string;
  sortCriteria: "asc" | "desc";
};
export const initSearchMethod = {
  keyword: "",
  start: "",
  end: "",
  sortingKey: "created_at",
  sortCriteria: "desc" as "asc" | "desc",
};
export const searchMethodState = atom<initSearchMethodType>(initSearchMethod);

const initSearchDialog = {
  displayValue: "",
  open: false,
  isLoading: false,
};
export const searchDialogState = atom(initSearchDialog);

export const resetSearchState = atom(null, (get, set) => {
  set(searchMethodState, { ...initSearchMethod });
  set(searchDialogState, { ...initSearchDialog });
});

export const scrollTopRandomState = atom(0);
export const scrollToTopState = atom(null, (get, set, update) => {
  set(scrollTopRandomState, Math.random());
});

type LoginOpenStateType =
  | { open: true; mode: "login" | "signup" | "switchOnlineUser" }
  | { open: false; mode?: never };

export const loginOpenState = atom<LoginOpenStateType>({
  open: false,
});

export const loadingState = atom(false);

export const mainScrollPaneState = atom(null);

export const displayModeState = atomWithStorage<"GRID" | "LIST">(
  "displayMode",
  "GRID",
);
export const currentPagePathToType = (path: string): currentPageType => {
  if (path === "/home/create/page")
    return { type: "PAGE_CREATE", id: null, path };
  if (path === "/home/setting") return { type: "SETTING", id: null, path };
  if (path.startsWith("/home/page/")) {
    const id = path.split("/")[3]; // 경로에서 ID 추출
    return { type: "PAGE_READ", id, path };
  }
  if (path.startsWith("/home/update/")) {
    const id = path.split("/")[3]; // 경로에서 ID 추출
    return { type: "PAGE_UPDATE", id, path };
  }
  return { type: "HOME", id: null, path: "/" };
};

export type currentPageType = {
  type: "HOME" | "PAGE_READ" | "PAGE_UPDATE" | "PAGE_CREATE" | "SETTING";
  id: string | null;
  path: string;
};
export const currentPageState = atom<currentPageType>({
  type: "HOME",
  id: null,
  path: "/",
});
export const syncingState = atom(false);
export const runSyncIdState = atom(0);
export const runSyncState = atom(null, (get, set, update) => {
  set(runSyncIdState, get(runSyncIdState) + 1);
});
export const noticeHistoryState = atomWithStorage("noticeHistory", {
  isOfflineUserFirstWrite: true,
  isOfflineUserFirstCamera: true,
  isIOSEnterProblem: true,
});
export const contentListMessageState = atom<string>("");
type focusGlobalInputType = {
  mode: "page" | "search";
  seed: number;
};
export const focusGlobalInputRandomState = atom<focusGlobalInputType>({
  mode: "page",
  seed: 0,
});
export const focusGlobalInputState = atom(
  null,
  (get, set, update: "page" | "search") => {
    set(focusGlobalInputRandomState, { mode: update, seed: Math.random() });
  }
);
if (process.env.NODE_ENV !== "production") {
  aiAtom.debugLabel = "searchAtom";
  chatSessionState.debugLabel = "chatSessionAtom";
  refreshSeedAfterContentUpdate.debugLabel = "refreshSeedAfterContentUpdate";
  typeSelectorState.debugLabel = "typeSelectorState";
  snackbarState.debugLabel = "snackbarState";
  openSnackbarState.debugLabel = "openSnackbarState";
  darkModeState.debugLabel = "darkModeState";
  searchMethodState.debugLabel = "searchMethodState";
  searchDialogState.debugLabel = "searchDialogState";
  loginOpenState.debugLabel = "loginOpenState";
  displayModeState.debugLabel = "displayMode";
  loadingState.debugLabel = "loadingState";
  syncingState.debugLabel = "syncing";
  mainScrollPaneState.debugLabel = "mainScrollPaneState";
  currentPageState.debugLabel = "currentPageState";
  scrollToTopState.debugLabel = "scrollToTopState";
  confirmState.debugLabel = "confirmState";
  openConfirmState.debugLabel = "openConfirmState";
  closeConfirmState.debugLabel = "closeConfirmState";
  scrollTopRandomState.debugLabel = "scrollTopRandomState";
  runSyncState.debugLabel = "runSyncState";
  runSyncIdState.debugLabel = "runSyncIdState";
  noticeHistoryState.debugLabel = "noticeHistoryState";
  contentListMessageState.debugLabel = "contentListMessageState";
  focusGlobalInputRandomState.debugLabel = "focusGlobalInputState";
}
