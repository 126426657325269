import { Model } from "@nozbe/watermelondb";
import { field, text, readonly, date } from "@nozbe/watermelondb/decorators";

export default class Page extends Model {
  static table = "page";

  // @ts-ignore
  @text("title") title: string;

  // @ts-ignore
  @text("body") body: string;

  // @ts-ignore
  @field("is_public") is_public: boolean;

  // @ts-ignore
  @field("child_count") child_count: number;

  // @ts-ignore
  @field("parent_count") parent_count: number;

  // @ts-ignore
  @field("last_viewed_at") last_viewed_at: number;

  // @ts-ignore
  @field("img_url") img_url: string;

  // @ts-ignore
  @field("length") length: number;

  // @ts-ignore
  @date("created_at") createdAt;

  // @ts-ignore
  @date("updated_at") updatedAt;
}
