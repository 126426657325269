import { Q } from "@nozbe/watermelondb";
import { database } from "..";
import { createClient } from "@/supabase/utils/client";
import { userType } from "@/types";

export async function get(id: string, isOnlineUser: boolean) {
  const pageCollection = database.get(isOnlineUser ? "page" : "page_offline");
  const page = await pageCollection.find(id);
  return page;
}

export async function list(
  {
    rangeStart,
    rangeEnd,
    sortingKey,
    sortCriteria,
    searchKeyword,
  }: {
    rangeStart: number;
    rangeEnd: number;
    sortingKey: string;
    sortCriteria: "asc" | "desc";
    searchKeyword: string | null;
  },
  isOnline: boolean = true,
) {
  const pageCollection = database.get(isOnline ? "page" : "page_offline");
  const queryParams = [];
  queryParams.push(
    Q.sortBy(sortingKey, sortCriteria === "desc" ? Q.desc : Q.asc),
  );
  queryParams.push(Q.skip(rangeStart));
  queryParams.push(Q.take(rangeEnd - rangeStart));
  if (searchKeyword) {
    queryParams.push(
      Q.or(
        Q.where("title", Q.like(`%${searchKeyword}%`)),
        Q.where("body", Q.like(`%${searchKeyword}%`)),
      ),
    );
  }
  return await pageCollection.query(...queryParams).fetch();
}

export async function create(
  {
    id,
    title,
    body,
    is_public,
    length,
    img_url,
    created_at,
    updated_at,
  }: {
    id?: string;
    title: string;
    body: string;
    is_public: boolean;
    length: number;
    img_url: string | null;
    created_at?: string;
    updated_at?: string;
  },
  isOnlineUser: boolean,
) {
  const pageCollection = database.get(isOnlineUser ? "page" : "page_offline");

  // database.write() 호출 결과를 변수에 저장
  const newPage = await database.write(async () => {
    // pageCollection.create() 호출 결과를 반환
    return await pageCollection.create((page) => {
      if (id) {
        // @ts-ignore
        page._raw.id = id;
      }
      // @ts-ignore
      page.title = title;
      // @ts-ignore
      page.body = body;
      // @ts-ignore
      page.is_public = is_public;
      // @ts-ignore
      page.length = length;
      // @ts-ignore
      page.img_url = img_url;
      if (created_at) {
        // @ts-ignore
        page.createdAt = created_at;
      }
      if (updated_at) {
        // @ts-ignore
        page.updatedAt = updated_at;
      }
    });
  });

  // 생성된 객체의 정보를 반환
  return {
    id: newPage._raw.id, // 생성된 행의 ID
    // @ts-ignore
    title: newPage._raw.title,
    // @ts-ignore
    body: newPage._raw.body,
    // @ts-ignore
    is_public: newPage._raw.is_public,
    // @ts-ignore
    length: newPage._raw.length,
    // @ts-ignore
    img_url: newPage._raw.img_url,
    // @ts-ignore
    created_at: newPage._raw.created_at,
    // @ts-ignore
    updated_at: newPage._raw.updated_at,
  };
}

export async function update(
  {
    id,
    title,
    body,
    is_public,
    length,
    img_url,
    created_at,
    updated_at,
  }: {
    id: string;
    title: string;
    body: string;
    is_public: boolean;
    length: number;
    img_url: string | null;
    created_at?: string;
    updated_at?: string;
  },
  isOnlineUser: boolean,
) {
  const page = await get(id, isOnlineUser);
  await database.write(async () => {
    await page.update((self) => {
      // @ts-ignore
      page.title = title;
      // @ts-ignore
      page.body = body;
      // @ts-ignore
      page.is_public = is_public;
      // @ts-ignore
      page.length = length;
      // @ts-ignore
      page.img_url = img_url;
      if (created_at) {
        // @ts-ignore
        page.createdAt = created_at;
      }
      if (updated_at) {
        // @ts-ignore
        page.updatedAt = updated_at;
      }
    });
  });
}

export async function count(isOnlineUser: boolean) {
  const pageCollection = database.get(isOnlineUser ? "page" : "page_offline");
  return await pageCollection.query().fetchCount();
}

export async function remove(id: string, isOnlineUser: boolean) {
  const page = await get(id, isOnlineUser);
  if (!page) return;
  await database.write(async () => {
    await page.markAsDeleted();
  });
}

export async function destroyPermanently(id: string, isOnlineUser: boolean) {
  const page = await get(id, isOnlineUser);
  if (!page) return;
  await database.write(async () => {
    await page.destroyPermanently();
  });
}

export async function markAsDeletedAll(isOnlineUser: boolean) {
  const pageCollection = database.get(isOnlineUser ? "page" : "page_offline");
  const pages = await pageCollection.query().fetch();
  await database.write(async () => {
    pages.forEach(async (page) => {
      await page.markAsDeleted();
    });
  });
}

export async function destroyPermanentlyAll(isOnlineUser: boolean) {
  const pageCollection = database.get(isOnlineUser ? "page" : "page_offline");
  const pages = await pageCollection.query().fetch();
  await database.write(async () => {
    pages.forEach(async (page) => {
      await page.destroyPermanently();
    });
  });
}

export async function verify() {
  const supabase = createClient();
  const { data, count: onlineTotal } = await supabase
    .from("page")
    .select("*", { count: "exact", head: true });
  const offlineTotal = await count(true);
  const isEqual = onlineTotal === offlineTotal;
  return { isEqual, onlineTotal, offlineTotal };
}

export async function verifyStrong() {
  const BATCH = 100;
  const supabase = createClient();

  const { count: pageCount, error: pageCountError } = await supabase
    .from("page")
    .select("*", { count: "exact", head: true });

  if (pageCountError) {
    console.error("Failed to fetch page count:", pageCountError);
    throw new Error("Failed to fetch page count");
  }

  const onlineIds: string[] = [];
  if (pageCount !== null && pageCount > 0) {
    const totalBatches = Math.ceil(pageCount / BATCH);
    for (let i = 0; i < totalBatches; i++) {
      const start = i * BATCH;
      const end = start + BATCH - 1;
      const { data: pageIds, error: pagesError } = await supabase
        .from("page")
        .select("id")
        .range(start, end);
      pageIds?.forEach((page) => {
        onlineIds.push(page.id);
      });
      if (pagesError) {
        throw new Error("검증을 위해서 페이지 목록을 가져오는데 실패했습니다");
        continue;
      }
    }
  }

  const offlineTotal = await count(true);
  const offlineIds: string[] = [];
  const batch = 100;
  for (let i = 0; i < offlineTotal; i += batch) {
    let pages = await list(
      {
        rangeStart: i,
        rangeEnd: i + batch,
        sortingKey: "created_at",
        sortCriteria: "asc",
        searchKeyword: null,
      },
      true,
    );
    offlineIds.push(...pages.map((page) => page.id));
    // @ts-ignore
    pages = null;
  }
  const onlineIdsSet = new Set(onlineIds);
  const offlineIdsSet = new Set(offlineIds);
  const onlyOnlineIds = onlineIds.filter((id) => !offlineIdsSet.has(id));
  const onlyOfflineIds = offlineIds.filter((id) => !onlineIdsSet.has(id));
  return {
    isEqual: onlyOnlineIds.length === 0 && onlyOfflineIds.length === 0,
    onlyOnlineIds,
    onlyOfflineIds,
    onlineTotal: onlineIds.length,
    offlineTotal: offlineIds.length,
  };
}

export function loginMode(isOnlineUser: userType) {
  if (isOnlineUser === "named") {
    return true;
  } else if (isOnlineUser === "anonymous") {
    return false;
  } else {
    throw new Error("isOnlineUser is not valid");
  }
}

export async function pushOnlyOffline(onlyOfflineIds: string[]) {
  const supabase = createClient();
  const onlyOfflinePagesPromise = onlyOfflineIds.map((id) => {
    return get(id, true);
  });
  const onlyOfflinePages = await Promise.all(onlyOfflinePagesPromise);
  const insertData = onlyOfflinePages.map((page) => {
    return {
      id: page.id,
      // @ts-ignore
      title: page.title,
      // @ts-ignore
      body: page.body,
      // @ts-ignore
      is_public: page.is_public,
      // @ts-ignore
      length: page.length,
      // @ts-ignore
      img_url: page.img_url,
      // @ts-ignore
      created_at: page.createdAt,
      // @ts-ignore
      updated_at: page.updatedAt,
    };
  });
  const result = await supabase.from("page").insert(insertData);
  return result;
}

export async function pullOnlyOnline(onlyOnlineIds: string[]) {
  const supabase = createClient();
  const result = await supabase
    .from("page")
    .select("*")
    .in("id", onlyOnlineIds);
  if (result.error === null && result.data.length > 0) {
    result.data.forEach(async (page) => {
      const createResult = await create(
        {
          id: page.id,
          title: page.title,
          body: page.body,
          is_public: page.is_public,
          length: page.length,
          img_url: page.img_url,
          updated_at: page.updated_at,
          created_at: page.created_at,
        },
        true,
      );
    });
  }
}
