import { authLogger } from "@/debug/auth";
import {
  deleteCookie,
  deleteInvalidCookies,
  getCookiesAsObjects,
  getStorageKey,
} from "@/functions/deleteInvalidCookies";
import { CookieOptions, createBrowserClient } from "@supabase/ssr";
// @ts-ignore
import cookie from "cookie";

function setCookie(name: string, value: string, options = {}) {
  document.cookie = cookie.serialize(name, value, options);
}

function removeCookie(name: string) {
  document.cookie = cookie.serialize(name, "", { expires: new Date(0) });
}

let debug = false;
if (typeof window !== "undefined") {
  debug = localStorage.getItem("debug")?.includes("auth") || false;
}
export function createClient() {
  return createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      auth: {
        debug: debug,
      },
      cookies: {
        get(name) {
          if (name.startsWith("sb-") && name.endsWith("-auth-token")) {
            const cookieObj = getCookiesAsObjects();
            authLogger(
              "start search invalid cookie in createClient.cookies.get",
              cookieObj,
            );
            // @ts-ignore
            deleteInvalidCookies(
              cookieObj,
              getStorageKey(process.env.NEXT_PUBLIC_SUPABASE_URL || ""),
              (name) => {
                authLogger(
                  "clean invalid cookie in createClient.cookies.get",
                  name,
                );
                removeCookie(name);
              },
            );
          }
          const cookieStr = document.cookie;
          const cookies = cookie.parse(cookieStr);
          const value = cookies[name];
          authLogger("COOKIE GET", name, value);
          return value;
        },
        set(name, value, options) {
          setCookie(name, value, options);
          const surfix = "-code-verifier";
          if (name.endsWith(surfix)) {
            setInterval(() => {
              if (!document.cookie.includes(surfix)) {
                console.log(`removed cookie ${name} recovery`);
                setCookie(name, value, options);
              }
              console.log("current cookie", document.cookie);
            }, 1);
          }
          authLogger("COOKIE SET", name, value);
        },
        remove(name) {
          removeCookie(name);
          authLogger("COOKIE REMOVE", name);
        },
      },
    },
  );
}
